import React from 'react'
import { Nav, Navbar, Container, Image } from 'react-bootstrap'
import { MenuItems } from '../MenuItems/MenuItems'
import Logo from '../../images/Tabah Law-01.png'
import './Navbar.css'

const Navigation = () => {
    return (
        <div >
            <Navbar collapseOnSelect expand="sm" variant='dark' className="nav-bkg" >
                <div className="nav-overlay"></div>
                <Container>
                    <Navbar.Brand href='/' className="logo-container">
                        <Image src={Logo} className="logo-img" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav as='ul' className='ml-auto'>
                            {MenuItems.map((item, index) => {
                                return (
                                    <Nav.Item as='li' key={index}>
                                        <Nav.Link href={item.url} className={item.className}>{item.title}</Nav.Link>
                                    </Nav.Item>
                                )
                            })}
                        </Nav> 
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navigation;
