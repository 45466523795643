import React from 'react'
import './General.css'
import { Container, Image } from 'react-bootstrap'
import Liability from '../../../images/liability.png';

const General = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>General Liability</h1>
                <p>
                Tabah Law, P.C.’s general liability practice focuses on personal injury, property damage, premises liability and other generalized torts.   Tabah Law, P.C. aggressively litigates these actions, including investigating the alleged incident, obtaining necessary experts, drafting motions, and advocating during all phases of court.
                </p>
                <div className="general-practice-img-container">
                    <Image className="practice-img" src={Liability} />
                </div>
            </Container>
        </div>
    )
}

export default General