import React from 'react'
import { useState, useEffect } from 'react'
import { Container, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'


const EditPost = (props) => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const history = useHistory()


    useEffect(() => {
        let postId = props.match.params.id;
        fetch(`/posts/${postId}`)
        .then(res => {
            if(res.ok){
                return res.json()
            }
        }).then(data => {
            setTitle(data.title)
            setDescription(data.description)
        }).catch(err => {
            console.log(err)
        })
    },[props.match.params.id])

    const titleHandleOnChange = e => {
        setTitle(e.target.value)
    }

    const descriptionHandleOnChange = e => {
        setDescription(e.target.value)
    }

    const submitHandler = e => {
        e.preventDefault()

        const postId = props.match.params.id
        axios.put(`/posts/${postId}`, {title: title, description: description })
            .then(response => {
                console.log(response)
            })
            .catch(err => {
                console.log(err)
            }
        )
        history.push('/news')
    }
    

    return (
        <div>
            <Container className="body-container news-page">
                <h1>Edit Post</h1>
                <hr />
                <div className="blog-wrapper">
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId="formBasicTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter title" 
                            name="title" 
                            onChange={titleHandleOnChange} 
                            value={title} 
                            />
                    </Form.Group>

                    <Form.Group controlId="formBasicDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            placeholder="Description" 
                            name="description" 
                            onChange={descriptionHandleOnChange} 
                            value={description}
                             />
                    </Form.Group>
                    <Button variant="primary" type="submit" >Update</Button>
                    <Link to="/news" className="btn btn-secondary ml-2">Cancel</Link>
                </Form>
                </div>
            </Container>
        </div>
    )
}

export default EditPost;