import React from 'react'
import { Container, Image } from 'react-bootstrap'
import ConsumerPic from '../../../images/consumer.png';

const Consumer = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>Consumer Financial Services Litigation</h1>
                <p>
                Tabah Law, P.C.’s consumer financial services litigation practice focuses on all aspects of the relationship between a financial institution, such as a bank or lender and the consumer, i.e., a natural person (usually the person who entered into the financial dealing with the bank or lender).  Generally, federal and California state laws require a financial institution to conform with specific procedures when servicing a loan and/or engaging in debt collection practices.  These procedures encompass the means and methods a financial institution utilizes to contact a consumer.  If a financial institution flouts these procedures, the institution may be precluded from engaging in the collection activity and may otherwise be liable for statutory damages, penalties, and fees specified in the law.  Whether student loans, mortgages, credit card, or other forms of debt collection, Tabah Law, P.C. intersects to protect the interests of its clients.
                </p>
                <div className="practice-img-container">
                    <Image className="practice-img" src={ConsumerPic} />
                </div>
            </Container>
        </div>
    )
}

export default Consumer