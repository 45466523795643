import React from 'react'
import './Initial.css'
import { Container } from 'react-bootstrap'

const Home = () => {
    return (
        <div>
            <div className="initial-hero-container">
                <div className="initial-hero-overlay"></div>
                <div className="initial-img-container"></div>
                <h1 className="initial-hero-title">Dedicated. Integrity. Justice. Tenacious.</h1>
            </div>
            <Container className="initial-page">
                <h2 className="initial-title second-title">A FIRM COMMITTED TO EXCELLENCE! </h2>
                <p>
                Tabah Law, P.C. is a full-service law firm dedicated to vigorously fighting on behalf of its clients and obtaining optimal results. We are experienced, proven, and committed to handling your legal matter as efficiently and effectively as possible. 
                </p>
            </Container>
        </div>
    )
}

export default Home