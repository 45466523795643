import React from 'react'
import './Footer.css'
import { Navbar, Container } from 'react-bootstrap'

const Footer = () => {
    return (
        <div>
            <Navbar collapseOnSelect variant='dark' className='footer'>
                <Container className="footer-layout">
                    <div className="footer-top">
                        <div className="footer-left">
                            <h4 className="consultation">Contact Us For A Free Consultation!</h4>
                            <div className="hours-container">
                                <p className="hours"><span>Office Hours:</span> <br/>M-F 9am-6pm</p>
                                <p className="address"><span>Location: </span><br />
                                    355 South Grand Avenue, Suite 2450<br />
                                    Los Angeles, California 90071
                                </p>
                            </div>
                        </div>
                        <div className="footer-right">
                            <p className="email"><span>Email:</span><br className="mobile-break"/> <a href='mailto:etabah@tabahlaw.com'>etabah@tabahlaw.com</a></p>
                            <p className="phone-number"><span>Phone:</span><br className="mobile-break"/> 213-986-3507</p>
                            <p className="fax"><span>Fax:</span><br className="mobile-break"/> 213-261-0875</p>
                        </div>
                    </div>
                    <div className="disclaimer-container">
                        <p className="disclaimer">
                            The information on this website is for general information purposes only. Nothing on this site should be taken as legal advice for any individual case or situation. This information is not intended to create, and receipt or viewing does not constitute, an attorney-client relationship.
                        </p>
                    </div>
                    <hr />
                    <p className="copyright">Copyright 2021 &copy; All rights Reserved.</p>
                </Container>
            </Navbar>
        </div>
    )
}

export default Footer
