import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './components/Navbar/Navbar'
import Initial from './components/Initial/Initial'
import Home from './components/Home/Home'
import About from './components/About/About'
import Services from './components/Services/Services'
import News from './components/News/News'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import Labor from './components/Services/Labor/Labor'
import CivilRights from './components/Services/CivilRights/CivilRights'
import Consumer from './components/Services/Consumer/Consumer'
import Product from './components/Services/Product/Product'
import Commercial from './components/Services/Commercial/Commercial'
import General from './components/Services/General/General'
import Appellate from './components/Services/Appellate/Appellate'
import NewPost from './components/News/NewPost'
import PostDetails from './components/News/PostDetails'
import EditPost from './components/News/EditPost'

class App extends Component {
  render () {
    return (
      <div>
        <Router>
          <header>
            <Navigation />
          </header>
          <div>
            <Switch>
              <Route exact path='/' component={Initial} />
              <Route path='/home' component={Home} />
              <Route path='/about' component={About} />
              <Route path='/services' component={Services} />
              {/* <Route path='/news' component={News} /> */}
              <Route path='/newpost' component={NewPost} />
              <Route path='/postdetails/edit/:id' component={EditPost} />
              <Route path='/postdetails/:id' component={PostDetails} />
              <Route path='/contact' component={Contact} />
              <Route path='/labor' component={Labor} />
              <Route path='/civilrights' component={CivilRights} />
              <Route path='/consumer' component={Consumer} />
              <Route path='/product' component={Product} />
              <Route path='/commercial' component={Commercial} />
              <Route path='/general' component={General} />
              <Route path='/appellate' component={Appellate} />
            </Switch>
          </div>
          <footer>
              <Footer />
          </footer>
        </Router>
      </div>
    );
  }
}

export default App;
