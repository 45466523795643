import React from 'react'
import './Labor.css'
import { Container, Image } from 'react-bootstrap'
import LaborEmployment from '../../../images/labor.png';

const Labor = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>Labor & Employment</h1>
                <p>
                Tabah Law, P.C.'s Labor & Employment practice focuses on all areas of the employer-employee relationship, including whether such a relationship exists.  This would play out, for example, if a person has been wrongly misclassified as an independent contractor when the law entitles said person to employee status.
                </p>
                <p>
                Additionally, Tabah Law, P.C., focus in the employer-employee relationship encompasses the work environment, as well interpersonal communications amongst the staff.  Amongst other things, Federal and California law prohibit an employer from harassing, retaliating, or discriminating against an employee when predicated, or otherwise based, on certain protected classifications. These protected classifications include race, sex, gender, religion, and disability.  For instance, the law precludes an employer from discharging, demoting, or otherwise treating adversely an employee due to his or her race, sex, gender, religion, etc.
                </p>
                <p>
                Finally, Tabah Law focus in the employer-employee relationship encompasses payment and wages.  With certain exceptions, an employer must provide an employee with overtime pay at the rate of 1.5 times his or her regular rate of pay for all hours worked in excess of 8 per day and 40 per week.  Additionally, again with certain exceptions, an employer must provide an employee an uninterrupted thirty-minute meal period as well as two uninterrupted 10-minute breaks when the employee works beyond a specified number of hours.
                </p>
                <div className="practice-img-container">
                    <Image className="practice-img" src={LaborEmployment} />
                </div>
            </Container>
        </div>
    )
}

export default Labor
