import React from 'react'
import { useState } from 'react'
import { Container, Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const NewPost = () => {
    
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const history = useHistory()

    const submitHandler = e => {
        e.preventDefault()
        console.log({title: title, description: description })

        axios.post('/posts', 
            {title: title, description: description })
            .then(response => {
                console.log(response)
            })
            .catch(err => {
                console.log(err)
            }
        )
        history.push('/news')
    }
    

    return (
        <div>
            <Container className="body-container news-page">
                <h1>New Post</h1>
                <hr />
                <div className="blog-wrapper">
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId="formBasicTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter title" 
                            name="title" 
                            onChange={(e) => setTitle(e.target.value)} 
                            value={title} />
                    </Form.Group>

                    <Form.Group controlId="formBasicDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            placeholder="Description" 
                            name="description" 
                            onChange={(e) => setDescription(e.target.value)} 
                            value={description} />
                    </Form.Group>
                    <Button variant="primary" type="submit" >Submit</Button>
                    <Link to="/news" className="btn btn-secondary ml-2">Cancel</Link>
                </Form>
                </div>
            </Container>
        </div>
    )
}

export default NewPost;