export const MenuItems = [
    {
        title: 'The Firm',
        url: '/home',
        className: 'nav-button'
    },
    {
        title: 'The Founder',
        url: '/about',
        className: 'nav-button'
    },
    {
        title: 'Practice Areas',
        url: '/services',
        className: 'nav-button'
    },
    // {
    //     title: 'News',
    //     url: '/news',
    //     className: 'nav-button'
    // },
    {
        title: 'Contact Us',
        url: '/contact',
        className: 'nav-button'
    }
]