import React from 'react'
import './Home.css'
import { Container, Image } from 'react-bootstrap'
import Elvin from '../../images/DSC_1046 copy 4.JPG'

const Home = () => {
    return (
        <div>
            <div className="hero-container">
                <div className="hero-overlay"></div>
                <div className="hero-img-container"></div>
                <h1 className="hero-title home-title">About the Firm</h1>
            </div>
            <Container className="body-container home-page">
                {/* <h1>About the Firm</h1>
                <hr /> */}
                <div className="home-elvin-container">
                    <Image src={Elvin} className="home-elvin-img" />
                </div>
                <p>
                Tabah Law, P.C. strives to  be a firm which provides unique attention and detail to each legal matter.   A civil rights or employment lawsuit may involve organizing and emphasizing particular facts to convey a persuasive narrative.  A consumer lawsuit may require sifting through and interpreting dense, muddled, and conflicting documents to determine the correct application.  A product liability or personal injury matter may necessitate several experts to establish, or otherwise refute, causation and liability. With so many divergent paths, many of which overlap, facing a lawsuit, either as a plaintiff or a defendant, can be a daunting process.  Tabah Law, P.C. streamlines that process.  We navigate the law to provide optimal results for our clients.  Tabah Law, P.C. will not be outworked, outmaneuvered, or outwitted.  We strive to provide the best work product and optimal results for our clients.  Additionally, we break down the intricacies of the law for our client’s such that our client’s become experts in their own cases.  
                </p>
                <p>
                Tabah Law, P.C. advocates zealously on behalf of its clients.  We represent individuals and businesses in all aspects of civil litigation.  Whether you are the victim of harassment, discrimination, assault, a broken contract, misappropriation, or otherwise tortious conduct on the one hand, or have been unjustly accused of harassment, discrimination, assault, breaking a contract, theft, etc., on the other hand, you deserve and are entitled justice.  Tabah Law, P.C. embarks to provide swift, fierce, and unrelenting justice embedded with results targeted at maximizing client outcomes.
                </p>
            </Container>
        </div>
    )
}

export default Home
