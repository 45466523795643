import React, { useState } from 'react'
import './Contact.css'
import { Container, Form, Button } from 'react-bootstrap'

const Contact = () => {
    
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    return (
        <div>
            <Container className="contact-page">
                <Form action="https://getform.io/f/d006f867-9505-400e-b554-7f022a75544b" method="POST">
                    <h1>We would love to hear from you.</h1>
                    <p className="subtitle">We are based in Los Angeles. You may contact us by phone or email, or by filling out the form at the bottom of this page.</p>
                    <Form.Row className='formRow'>
                        <Form.Label className='labels'>Full Name</Form.Label>
                        <Form.Control type='text' name='fullName' placeholder='Full Name' onChange={(e) => setFullName(e.target.value)} value={fullName} />
                    </Form.Row>
                    <Form.Row className='formRow'>
                        <Form.Label className='labels'>Email Address</Form.Label>
                        <Form.Control type='email' name='email' placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} value={email} />
                    </Form.Row>
                    <Form.Row className='formRow'>
                            <Form.Label className='labels'>Description of Incident</Form.Label>
                            <Form.Text className='subMessage'>
                            Please provide a short description of the facts and circumstances surrounding your legal issue. Specifically, please provide a description of the events and/or circumstances which have given rise to your legal issue. Additionally, please include where the incident occurred.
                            </Form.Text>
                            <Form.Control as='textarea' name='message' placeholder="Message here..." rows={4} onChange={(e) => setMessage(e.target.value)} value={message} />
                    </Form.Row>
                    <Button variant='primary' type='submit' >Submit</Button>
                </Form>
            </Container>
        </div>
    )
}

export default Contact
