import React from 'react'
import './About.css'
import { Container, Image } from 'react-bootstrap'
import Elvin from '../../images/DSC_0961 copy 4.JPG'

const About = () => {
    return (
        <div>
            <div className="hero-container">
                <div className="hero-overlay"></div>
                <div className="about-img-container"></div>
                <h1 className="hero-title about-title">About the Founder</h1>
            </div>
            <Container className="body-container about-page">
                {/* <h1>About the Founder</h1>
                <hr /> */}
                <div className="about-elvin-container">
                    <Image src={Elvin} className="about-elvin-img" />
                </div>
                <p>
                Elvin-Mathias Itah Tabah founded Tabah Law, P.C. in 2021.  Growing up as a first-generation Cameroonian immigrant, Mr. Tabah developed a relentless work ethic and a passion for helping others.  Mr. Tabah fully ingratiates this passion and tenacity in all aspects of his decision-making; ensuring that all options and intricacies of the law are explored for his clients.  As a former division one track and field athlete, Mr. Tabah intercedes to win on his client’s behalf.  
                </p>
                <p>
                Mr. Tabah focuses his practice in multiple areas of civil litigation including, but not limited to, employment litigation, civil rights, financial services litigation, commercial litigation, product liability, and general liability.  Regardless of the legal issue faced,  Mr. Tabah possesses the skill and expertise to handle the matter on  
                </p>
                <p>
                Mr. Tabah graduated from the UCLA School of Law in 2012.  After graduating from law school, Mr. Tabah worked as an attorney at a variety of law firms including small, large, and boutique.  Mr. Tabah has litigated numerous cases in both state and federal court from individual employment discrimination to wage and hour class action, from products liability to foreclosure defense, and from police brutality to unfair debt collection practices.  Furthermore, Mr. Tabah has advocated on behalf of plaintiffs and defendants alike.  As such, Mr. Tabah understands that mindset of a plaintiff or a defendant, and the necessary strategies to achieve optimal results.   
                </p>
                <p>
                Finally, Mr. Tabah prides himself on his legal acumen, writing, and tenacity.  Additionally, Mr. Tabah enjoys the research integral and necessary for each case—locating the dispositive needle in the haystack which results in optimal outcomes.  Nothing provides Mr. Tabah with more satisfaction, then prevailing on his client’s behalf. 
                </p>
                <p>
                When he is not working on behalf of his clients, Mr. Tabah enjoys reading, working out, and spending time with family and friends.  
                </p>
            </Container>
        </div>
    )
}

export default About;
