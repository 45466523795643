import axios from 'axios'
import React from 'react'
import { useState, useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import './PostDetails.css'

const PostDetails = (props) => {
    const [details, setDetails] = useState({
        title: '',
        description: '',
        date: ''
    })

    const history = useHistory()

    useEffect(() => {
        let postId = props.match.params.id;
        fetch(`/posts/${postId}`)
        .then(res => {
            if(res.ok){
                return res.json()
            }
        }).then(data => {
            setDetails(data)
        }).catch(err => {
            console.log(err)
        })

    },[props.match.params.id])

    const onDelete = () => {
        const postId = details._id;
        axios.delete(`/posts/${postId}`)
            .then(response => {
                history.push('/news')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <Container className="body-container news-page">
                <Link to="/news" className="btn btn-secondary">&#8592; Back</Link>
                <h1>{details.title}</h1>
                <div className="blog-wrapper">
                    <p className="blog-description">{details.description}</p>
                    <p className="blog-date">{details.date}</p>
                </div>
                <Link to={`/postdetails/edit/${details._id}`} className="btn btn-warning">Edit</Link>
                <Button className="btn btn-danger ml-2" onClick={onDelete}>Delete</Button>
            </Container>
        </div>
    )
}

export default PostDetails;
