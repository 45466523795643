import React from 'react'
import './Commercial.css'
import { Container, Image } from 'react-bootstrap'
import Handshake from '../../../images/handshake.jpg';

const Commercial = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>Commercial Litigation</h1>
                <p>
                Tabah Law, P.C.’s commercial litigation practice focuses on disputes arising out of the business context.  These disputes include alleged breaches of contract, breach of fiduciary duty allegations, shareholder issues, derivative actions, business torts, unfair competition, fraud, and more.  Tabah Law, P.C. aggressively litigates these actions to obtain optimal results for its clients.
                </p>
                <div className="commercial-practice-img-container">
                    <Image className="practice-img" src={Handshake} />
                </div>
            </Container>
        </div>
    )
}

export default Commercial