import React from 'react'
import './Services.css'
import {Container} from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import { Practices } from './Practices'

const Services = () => {
    return (
        <div>
            <div className="hero-container">
                <div className="hero-overlay"></div>
                <div className="services-img-container"></div>
                <h1 className="hero-title">Practice Areas</h1>
            </div>
            <Container className="body-container services-page">
                {/* <h1>Practice Areas</h1>
                <hr/> */}
                {/* {Practices.map((practice, index) => {
                    return (
                        <div>
                            <h3 key={index} className="practice-title">{practice.title}</h3>
                            <div className="practice-img-container">
                                <Image src={practice.picture} className="practice-img" />
                            </div>
                            <p className="practice-description">{practice.description}</p>
                        </div>
                    )
                })} */}

                <ul className="practice-list">
                    <Link to="/labor" className="practice-links"><li>Labor & Employment</li></Link>
                    <Link to="/civilrights" className="practice-links"><li>Civil Rights</li></Link>
                    <Link to="/consumer" className="practice-links"><li>Consumer Financial Services Litigation</li></Link>
                    <Link to="/product" className="practice-links"><li>Product Liability</li></Link>
                    <Link to="/commercial" className="practice-links"><li>Commercial Litigation</li></Link>
                    <Link to="/general" className="practice-links"><li>General Liability</li></Link>
                    <Link to="/appellate" className="practice-links"><li>Appellate</li></Link>
                </ul>
            </Container>
        </div>
    )
}

export default Services
