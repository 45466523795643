import React from 'react'
import { Container, Image } from 'react-bootstrap'
import Wire from '../../../images/burning_wire.jpg';

const Product = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>Product Liability</h1>
                <p>
                Tabah Law, P.C.’s product liability practice focuses on accidents or injuries which result from defective products.  A manufacturer, distributor, or seller may be liable for injuries which result from defective products.  These defects include manufacturing defects, design defects, and warning defects (inadequate warnings).  Tabah Law, P.C. navigates the product liability landscape to assess whether the accident at issue involves a defective product, the nature of the defect, and the methods and steps to achieve a desirous outcome.
                </p>
                <div className="practice-img-container">
                    <Image className="practice-img" src={Wire} />
                </div>
            </Container>
        </div>
    )
}

export default Product