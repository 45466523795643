import React from 'react'
import { Container, Image } from 'react-bootstrap'
import Civil from '../../../images/civil.png';
import './CivilRights.css'

const CivilRights = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>Civil Rights</h1>
                <p>
                Tabah Law, P.C.’s civil rights practices focus on purported governmental breaches or encroachments of rights guaranteed, or otherwise protected, under the constitution or state law.  Amongst other things, this protection encompasses unlawful searches and seizures, wrongful arrests, excessive force, infringements on the parental relationship, the curtailing of freedom of speech, and more.
                </p>
                <div className="practice-img-container">
                    <Image className="practice-img" src={Civil} />
                </div>
            </Container>
        </div>
    )
}

export default CivilRights
