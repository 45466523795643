import React from 'react'
import './Appellate.css'
import { Container } from 'react-bootstrap'


const General = () => {
    return (
        <div>
            <Container className="body-container practice-container">
                <h1>Appellate</h1>
                <p>
                Tabah Law, P.C.’s appellate practice focuses on the procedure and process of overturning unfavorable judgments or preserving well-deserved victories.  Whether the judgment occurred at the pleading stage, evidentiary stage, or after trial, Tabah Law, P.C. will assess the efficacy of the appeal, as well as the exacting standard necessary to overturn the judgment.  When appealing an adverse state or federal court, Tabah Law, P.C. examines the underlying records from the trial court, analyzes and frames all the relevant factual and legal issues, and carefully crafts the briefs for the Court.  Tabah Law, P.C. handles a wide variety of civil appeals and writ proceedings in many areas of the law including labor & employment, civil rights, consumer financial services litigation, product liability, commercial litigation, and general liability.
                </p>
            </Container>
        </div>
    )
}

export default General